import { Form, Formik, FormikErrors, FormikHelpers } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import { resetPassword } from "web/apis";
import { FormikTextField } from "web/react/components/forms/formik";
import { Button } from "web/react/emo/button";
import { Heading } from "web/react/emo/heading";
import { VStack } from "web/react/emo/v-stack";
import { View } from "web/react/emo/view/view";
import { BackButton } from "web/react/pages/account/signup-or-login-page/back-button";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import { validateEmail } from "web/script/utils/validate";
import styles from "./forgot-password-screen.module.css";

type ForgotPasswordFormValues = {
    email: string;
};

interface ForgotPasswordScreenProps {
    email: string;
    onSuccess: (value) => void;
}

export const ForgotPasswordScreen = ({
    email,
    onSuccess,
}: ForgotPasswordScreenProps): JSX.Element => {
    const navigate = useNavigate();

    const initialValues: ForgotPasswordFormValues = {
        email: email,
    };

    async function onSubmit(
        values: ForgotPasswordFormValues,
        actions: FormikHelpers<ForgotPasswordFormValues>
    ): Promise<void> {
        actions.setSubmitting(true);

        try {
            await resetPassword(values.email);
            onSuccess(values.email);
            return navigate("/forgot-password/email-sent");
        } catch (e) {
            analytics.event("forgot_password", "field_email");
        }

        actions.setSubmitting(false);
    }

    function validate(values: ForgotPasswordFormValues): FormikErrors<ForgotPasswordFormValues> {
        const errors: FormikErrors<ForgotPasswordFormValues> = {};

        if (!values.email || !validateEmail(values.email)) {
            analytics.event("forgot_password", "invalid", "field_email");
            errors.email = gettext("account.register.incorrect_email.label");
        }
        return errors;
    }

    return (
        <View className={styles.wrapper}>
            <VStack spacing="md">
                <BackButton
                    onBack={() => {
                        analytics.event("forgot_password", "click", "back_button");
                        navigate("/login");
                    }}
                />
                <Heading textStyle={"headline-v2"} as="h1">
                    {gettext("account.send_email_reset_password.title")}
                </Heading>

                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validate={validate}
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                    {({ isSubmitting }): JSX.Element => (
                        <Form noValidate>
                            <VStack spacing="md">
                                <FormikTextField
                                    label="Email"
                                    name="email"
                                    type="email"
                                    onFocus={() => {
                                        analytics.event(
                                            "forgot_password",
                                            "fill_field",
                                            "field_email"
                                        );
                                    }}
                                    autoCapitalize="off"
                                    autoFocus={false}
                                    required={true}
                                />
                                <div>
                                    <Button
                                        disabled={isSubmitting}
                                        type="submit"
                                        width="full"
                                        title={gettext("account.send_reset_link.cta")}
                                        onClick={() =>
                                            analytics.event(
                                                "forgot_password",
                                                "click",
                                                "send_reset_link_button"
                                            )
                                        }
                                        textStyle={"callout-v2"}
                                        upperCase
                                    />
                                </div>
                            </VStack>
                        </Form>
                    )}
                </Formik>
            </VStack>
        </View>
    );
};
