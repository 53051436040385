import { Form, Formik, FormikErrors, FormikHelpers } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Conditional } from "web/react/components/conditional";
import {
    FormikCheckbox,
    FormikPasswordFieldWithValidation,
} from "web/react/components/forms/formik";
import { UserEmailCard } from "web/react/components/user-email-card";
import { EXPLICIT_EMAIL_CONSENT_COUNTRIES } from "web/react/constants";
import { Button } from "web/react/emo/button";
import { Heading } from "web/react/emo/heading";
import { VStack } from "web/react/emo/v-stack";
import { View } from "web/react/emo/view";
import { BackButton } from "web/react/pages/account/signup-or-login-page/back-button";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import environment from "web/script/modules/environment";
import requester from "web/script/modules/requester";
import navigate from "web/script/utils/navigate";
import { buildLanguageAwareUrlPath } from "web/script/utils/url";
import {
    DEFAULT_PASSWORD_REQUIREMENTS,
    PASSWORD_RULES,
    validatePassword,
} from "web/script/utils/validate";
import styles from "./signup-screen.module.css";

type SignupFormValues = {
    password: string;
    marketing_emails: boolean;
};

type SignupFormProps = {
    email: string;
};

const SignupForm = ({ email }: SignupFormProps): JSX.Element => {
    const requiresOptIn = EXPLICIT_EMAIL_CONSENT_COUNTRIES.has(environment.get("country"));

    const initialValues = { password: "", marketing_emails: !requiresOptIn };

    async function onSubmit(
        values: SignupFormValues,
        actions: FormikHelpers<SignupFormValues>
    ): Promise<void> {
        actions.setSubmitting(true);

        const path = buildLanguageAwareUrlPath("account/register/");

        try {
            const response = await requester.post(path, {
                email,
                sign_up_language: environment.get("language"),
                ...values,
            } as any);

            if (response && response.next) {
                navigate(response.next);
                return;
            }

            throw new Error(response.data?.errors?.join?.("\n") ?? "Unknown error");
        } catch (e) {
            analytics.event("signup", "server_error");
        }

        actions.setSubmitting(false);
    }

    function validate(values: SignupFormValues): FormikErrors<SignupFormValues> {
        const errors: FormikErrors<SignupFormValues> = {};

        if (!values.password) {
            analytics.event("signup", "required", "field_password");
            errors.password = gettext("account.invalid_password_format.label");
        } else if (!validatePassword(values.password)) {
            analytics.event("signup", "invalid", "field_password");
            errors.password = gettext("account.invalid_password_format.label");
        }

        return errors;
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={validate}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {({ isSubmitting }): JSX.Element => (
                <Form noValidate>
                    <VStack spacing="md">
                        <VStack spacing="xs">
                            <FormikPasswordFieldWithValidation
                                id="password"
                                name="password"
                                onFocus={() => {
                                    analytics.event("signup", "fill_field", "field_password");
                                }}
                                label={gettext("account.create_password.title")}
                                autoComplete="new-password"
                                minLength={8}
                                passwordrules={PASSWORD_RULES}
                                requirements={DEFAULT_PASSWORD_REQUIREMENTS}
                                required
                            />
                            <Conditional check={requiresOptIn}>
                                <FormikCheckbox
                                    name="marketing_emails"
                                    onChange={(e) => {
                                        analytics.event(
                                            "signup",
                                            e.target.checked ? "tick_box" : "untick_box",
                                            "tickbox_marketing"
                                        );
                                    }}
                                    label={gettext(
                                        "account.register.legal_agreement.sign_up_checkbox_label"
                                    )}
                                />
                            </Conditional>
                        </VStack>
                        <div data-testid="sign-up-button">
                            <Button
                                variant={"primary"}
                                disabled={isSubmitting}
                                onClick={() => analytics.event("signup", "click", "signup_button")}
                                type="submit"
                                width="full"
                                title={gettext("homepage.module_value_props_sign_up_button")}
                                textStyle={"callout-v2"}
                                upperCase
                            />
                        </div>
                    </VStack>
                </Form>
            )}
        </Formik>
    );
};

type SignupScreenProps = {
    email: string;
};

export function SignupScreen({ email }: SignupScreenProps): JSX.Element {
    const navigate = useNavigate();

    return (
        <View className={styles.wrapper}>
            <BackButton
                onBack={() => {
                    analytics.event("signup", "click", "back_button");
                    navigate("/");
                }}
            />
            <VStack spacing={"md"}>
                <Heading textStyle={"headline-v2"} as="h1">
                    {gettext("account.create_account.title")}
                </Heading>
                <UserEmailCard
                    email={email}
                    onClick={() => {
                        analytics.event("signup", "click", "change_email_button");
                        navigate("/");
                    }}
                />
                <SignupForm email={email} />
            </VStack>
        </View>
    );
}
