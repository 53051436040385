import "web/react/emo/theme.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"web/react/emo/theme.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6WUTW/bMAyG7/sVRHzZgHSwPizL3Em2JayH7bDuPjip06b5cJqkSdth/32gMxQzPZ0G+fRQpqiXfIX7rjvCz3cAV1c/xP52JcU5RUjSNP00gAIhsdpY4YdcIiRBhdQXQ64QkjJzOrdDrhGSIs1zlQ15hpAYlynB9hvK40sV5JDnCImvvA3lkFuEpCorGVj+guovrHBhyBuEJAuZy1n9M4REV9oaMeRzhKRW0kumwy3p4L3zbshb4qVVluVZEHc60+xed6RPJmrB7nWPkMhamcDqX1Kzgig5f+ibaF3KdFiRbiY4nw/5mvQvvcpZ3zd9HhdStn/bc21SNeRdRJ9dRJ/HiD77iD6HiD7HPn9h+dw+EQ8h82bIT/3+ShjGzz13tWL3eu7nKnc8/wtCIpwyJeOvpLP1ZWC6CXJXbqypucHIYSKTpa1ZgCyWOmE0K1WQx2QurWSzK3SkKEEuqx0tFjB9G4ILPEA+C1nQ3H+CjFb7uq55VUXsjObSisBHVcxih88vj4sKrBmC3OZzWizQxg4nv1WeFguQ4SpNiwXIcUVJiwXIcnlKiwXIc9rTYoFVrFHry1M7fm03CPu7WfM+ncKf76P+wPZsYz93sQJ3sT8eI6+7IBvm0pQFs6c4RHwujpGHR5ATq1wayYU+RawlzrExfo5N0kss8BppjUwjkkgREVHKyMBIhcD3agS9e2YwQ7AjaBCEHNEcQZgRtQhynLZAUOmINghqnHeGoMd75wh6XNktghmf1iLYcYYFQjGu947uNt58j3DztDwcWrjeHtcsuHwLfum2HQs+IFyfmj3cdIsjC60GSacw+dyuT+1xOW/ga/vUTqbwBqbg9stmPYVDsz1cHdr9csGSrf86ZwqT78tNe4Cv7Rm+dZtmO5lCT6bwr383gwv8ZyFb6hcfrg5BZxzuELLRzkcEM4J7BKEuM/frN5j8aoMHCgAA\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "web/react/components/save-for-later-button/save-for-later.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"web/react/components/save-for-later-button/save-for-later.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA8VVy46bMBTd5yuuNIqUSHWECZkGZ9NF1d+oDL4knoCNbOc1Vf69whAGMoR2UTViAfbxPec+zUIHb/twHcCvCYCQtsz5hUGS63S/mQAU3GylIkZud44BDcpztesMVzbTpmBgU57jjC6i+WZynSxqNjrI1sKhh0ttpZNaMTCYcyePuOkaZTl6LZ7LrSLSYWEZpKgcmmr77WCdzC4k1cqhcl3oJIXbMThyMyPkJzViH9JTmMwrbId1JIOgPqLJcn1isJNCoKr2Up1rc38+8MczrRyx8h0ZhLROTHowtjpeall708a89DEnPN1vjT4oweAlW1dPZZZoI9AQw4U8WAarYNqxjPrJfEJemhy8cM4H425dXd0VlidW5wfnC+t0yYDQZZ2oxpfXdc/+9a/tm4YkNBrj+/pvUtfyxSON6/2jPW8Kfr6R9aaGaCO3Ug0I8BGBehQZBPc1iO6G8jF94ulv5fwRL4PwewdOx2HhYV3yVLqL96OF8E+F8y0NkGPm2kXnFvGfOXc4I1E8/QJkFUzrkbyp0Wr1TqQSeGYQdm8TprSbLZTeVfM7Z/4Fwz7TzViAo0z4OPrksdp18q1AITnY1CAq4ErArJCKNC0Sx2F5nnuzhmPtF4+SeUshrVM4dGVfP7jCfghxQ/24U1q0kW5PAl0FhQXkFjd9jo9fQDj/JM/Tqnv/i36wiO/1o+eGHz0n/OtvIcW31dMHAAA=\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var animatedEmoHeart = 'o0jk286';
export var animatedHeart = 'o0jk285';
export var heartPressed = 'o0jk28d';
export var heartUnpressed = 'o0jk28e';
export var heartWithAnimation = 'o0jk287';
export var icon = 'o0jk282';
export var iconSaved = 'o0jk28b';
export var oldIcon = 'o0jk283';
export var small = 'o0jk284';
export var smallSaved = 'o0jk28c';
export var svg = 'o0jk280';
export var svgEmoIcon = 'o0jk28a';
export var svgIcon = 'o0jk289';
export var svgSaved = 'o0jk281';
export var svgSavedTick = 'o0jk288';