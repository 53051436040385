import { Form, Formik, FormikErrors, FormikHelpers } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { magicLinkLogin, passwordLogin } from "web/apis";
import { Divider } from "web/react/components/divider";
import { FormikPasswordField } from "web/react/components/forms/formik";
import {
    generateSearchParams,
    getTypeDetails,
} from "web/react/components/soft-signup-dialog/soft-signup-utils";
import { UserEmailCard } from "web/react/components/user-email-card";
import { Button } from "web/react/emo/button";
import { Heading } from "web/react/emo/heading";
import { Text } from "web/react/emo/text";
import { VStack } from "web/react/emo/v-stack";
import { View } from "web/react/emo/view";
import { BackButton } from "web/react/pages/account/signup-or-login-page/back-button";
import { closeCustomerCaptureOverlay } from "web/redux/ducks/customer-capture-overlay";
import { ReduxStoreState } from "web/redux/store";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import globals from "web/script/modules/globals";
import userProfiler from "web/script/modules/userprofiler";
import storage from "web/script/utils/storage";
import styles from "./login-screen.module.css";

type LoginFormValues = {
    password: string;
};

type LoginFormProps = {
    email: string;
};

const LoginForm = ({ email }: LoginFormProps): JSX.Element => {
    const initialValues = {
        password: "",
    };

    const { captureType, designerId, next, productId, searchGender, searchTerm } = useSelector(
        (state: ReduxStoreState) => state.customerCaptureOverlay
    );
    const dispatch = useDispatch();

    const type = getTypeDetails(captureType);

    const { searchTermParam, searchGenderParam, nextParam } = generateSearchParams({
        next,
        captureType,
        searchTerm,
        searchGender,
    });

    async function onSubmit(
        values: LoginFormValues,
        actions: FormikHelpers<LoginFormValues>
    ): Promise<void> {
        actions.setSubmitting(true);

        const seenProducts = userProfiler.getSeenProducts();
        let productIds = [];

        if (seenProducts) {
            productIds = seenProducts.reduce((memo, product) => {
                memo.push(product.productId);
                return memo;
            }, []);
        }

        const body = {
            designer_id: designerId,
            invisible_pids: productIds.join(","),
            product_ids: productId,
            search_query: searchTermParam,
            search_gender: searchGenderParam,
            next: nextParam,
        };

        try {
            const response = await passwordLogin(email, values.password, body, type);

            if (response && response?.success) {
                dispatch(closeCustomerCaptureOverlay());
                const redirectURL =
                    storage.get("new_user_to_sign_up", null, true) === "active"
                        ? globals.window.location.href
                        : (response.data?.redirect_url as string);

                storage.get("new_user_to_sign_up", null, true) === "active" &&
                    storage.set("new_user_to_sign_up", "complete", true);

                globals.window.location.href = redirectURL;
                return;
            }

            throw new Error(`Unknown error ${JSON.stringify(response.errors)}`);
        } catch (error) {
            actions.setFieldError(
                "password",
                gettext("account.error.email_password_combination.label")
            );
            analytics.event("login", "server_error");
        }
        actions.setSubmitting(false);
    }

    function validate(values: LoginFormValues): FormikErrors<LoginFormValues> {
        const errors: FormikErrors<LoginFormValues> = {};
        if (!values.password) {
            analytics.event("login", "required", "field_password");
            errors.password = gettext("account.error.email_password_combination.label");
        }
        return errors;
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={validate}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {({ isSubmitting }): JSX.Element => (
                <Form noValidate>
                    <VStack spacing="md">
                        {/* add for purpose of PW managers */}
                        <input
                            hidden
                            name="username"
                            autoComplete="username email"
                            defaultValue={email}
                        />

                        <FormikPasswordField
                            id="password"
                            name="password"
                            autoComplete="current-password"
                            onFocus={() => {
                                analytics.event("login", "fill_field", "field_password");
                            }}
                            label={gettext("account.authentication.password")}
                            required
                        />
                        <div data-testid="login-button">
                            <Button
                                variant={"primary"}
                                width="full"
                                onClick={() =>
                                    analytics.event("login", "click", "login_with_password_button")
                                }
                                disabled={isSubmitting}
                                type="submit"
                                title={gettext("account.log_in.title")}
                                textStyle={"callout-v2"}
                                upperCase
                            />
                        </div>
                    </VStack>
                </Form>
            )}
        </Formik>
    );
};

type LoginScreenProps = {
    email: string;
};

export const LoginScreen = ({ email }: LoginScreenProps): JSX.Element => {
    const navigate = useNavigate();

    async function onClick(): Promise<void> {
        analytics.event("login", "click", "login_with_magic_link_button");

        const response = await magicLinkLogin(email);

        if (response.success) {
            navigate("/login/email-sent");
        }
    }

    return (
        <View className={styles.wrapper}>
            <VStack spacing={"md"}>
                <BackButton
                    onBack={() => {
                        analytics.event("login", "click", "back_button");
                        navigate("/");
                    }}
                />
                <Heading as={"h1"} textStyle={"headline-v2"}>
                    {gettext("account.register.welcome_back.title")}
                </Heading>
                <UserEmailCard
                    email={email}
                    onClick={() => {
                        analytics.event("login", "click", "change_email_link");
                        navigate("/");
                    }}
                />
                <LoginForm email={email} />
                <VStack spacing="md">
                    <Divider>
                        <Text textStyle={"caption-3"}>
                            {gettext("account.register.or_no_password.label")}
                        </Text>
                    </Divider>
                    <div>
                        <Button
                            variant="secondary"
                            width="full"
                            onClick={onClick}
                            title={gettext("account.register.email_link_sign_in.cta")}
                            textStyle={"callout-v2"}
                            upperCase
                        />
                    </div>
                    <Text textStyle={"caption-3"} align="center" upperCase>
                        <Link
                            to={"/forgot-password"}
                            onClick={(): void => {
                                analytics.event("login", "click", "forgot_password_link");
                            }}
                        >
                            {gettext("account.forgot_password.link")}
                        </Link>
                    </Text>
                </VStack>
            </VStack>
        </View>
    );
};
