import { useSelector as useReduxSelector } from "react-redux";
import { ReduxStoreState } from "web/redux/store";

/**
 * Returns a boolean to check whether we should be showing feed "landing content", that is
 * (seo) content tailored to the state of the filters when the user landed on this page. Once
 * the filters are changed, we want to hide this content since it may no longer match the feed
 * (e.g. if the original designer or product type has changed)
 */
export function useLandingContent(): boolean {
    const showLandingContent = useReduxSelector(
        (state: ReduxStoreState) => state.feedFiltersReducer.showLandingContent
    );

    return showLandingContent;
}
