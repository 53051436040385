import { StickerType } from "web/react/emo/sticker";
import { gettext } from "web/script/modules/django-i18n";
import {
    ProductBuyOptionsSerializer,
    ProductPriceSerializer,
    ProductScreenSize,
    ProductSerializer,
} from "web/types/serializers";
import { BuyOptionPromotionSerializer, ShippingInformation } from "./in-stock-product-page.context";

export const getCostForPaidShipping = (
    formattedThresholdPrice: string,
    price: number | string
): string =>
    !!formattedThresholdPrice && formattedThresholdPrice.length > 0
        ? // "Excl. {shipping_cost_format} shipping (free on orders over {free_shipping_threshold})"
          gettext("product.buy_area.excluding_shipping_free_over.label", {
              shipping_cost_format: price,
              free_shipping_threshold: formattedThresholdPrice,
          })
        : // "Excl. {shipping_cost_format} shipping"
          gettext("product.buy_area.excluding_shipping_info.label", {
              shipping_cost_format: price,
          });

export function getShippingInformation(
    activeBuyOption: ProductBuyOptionsSerializer
): ShippingInformation[] {
    const { shipping_info: shippingInfo, promotions } = activeBuyOption;

    const shippingInformation: ShippingInformation[] = [];

    if (shippingInfo.length === 0) {
        return shippingInformation;
    }

    const promotion = promotions.filter((promo) => promo.discount_type === "free_shipping");
    const formattedThreshold = promotion.length > 0 ? promotion[0].min_spend : "";

    const isShippingFree = shippingInfo[0].price === "Free";
    const shippingCost = isShippingFree
        ? gettext("general.free_shipping") // "Free shipping"
        : getCostForPaidShipping(formattedThreshold || "", shippingInfo[0].price);

    shippingInformation.push({
        info: shippingCost,
        isFree: isShippingFree,
    });

    const estimatedDelivery = shippingInfo[0].estimated_time;
    if (estimatedDelivery) {
        shippingInformation.push({
            info: estimatedDelivery,
            isFree: true,
        });
    }

    return shippingInformation;
}

export function getPromotion(
    product: ProductSerializer,
    buyOption: ProductBuyOptionsSerializer,
    termsUrl: string
): BuyOptionPromotionSerializer | null {
    const promo = buyOption.promotions.filter((promo) => promo.code || promo.discount_amount)[0];
    if (!promo) {
        return null;
    }

    return {
        tcs: [
            {
                // "Lyst promotion terms apply"
                message: gettext("in_stock_product.lyst_promotion_terms_link"),
                url: termsUrl,
            },
            {
                // "{retailer_name} promotion terms apply"
                message: gettext("in_stock_product.retailer_promotion_terms_link", {
                    retailer_name: product.retailer_name,
                }),
                url: buyOption.affiliate_url || "",
            },
        ],
        message: promo.description || "",
        promoCode: promo.code || "",
        url: buyOption.affiliate_url || "",
    };
}

export const DEFAULT_PRODUCT: ProductSerializer = {
    product_id: "",
    link_ids: [],
    canonical_slug: "",
    canonical_url: "",
    short_description: "",
    long_description: "",
    raw_color: null,
    color: null,
    designer: "",
    images: [],
    picker_thumbnail_url: "",
    product_sizes: null,
    lead_url: null,
    retailer_name: null,
    stock_availability_txt: null,
    descriptions: [],
    designer_category_url: "",
    is_saved_for_later: false,
};

const DEFAULT_PRODUCT_PRICE: ProductPriceSerializer = {
    amount: null,
    currency_code: "",
};

export const DEFAULT_PRODUCT_BUY_OPTION: ProductBuyOptionsSerializer = {
    retailer_name: "",
    retailer_slug: "",
    retailer_network: "",
    price: "",
    price_info: { ...DEFAULT_PRODUCT_PRICE },
    link_id: 123,
    on_sale: false,
    sale_price: null,
    sale_price_info: { ...DEFAULT_PRODUCT_PRICE },
    discount: null,
    in_stock: null,
    sizes: [],
    is_icon: null,
    checkout_flow: null,
    retailer_integration_type: null,
    affiliate_url: "",
    data_points: [],
    free_shipping: null,
    shipping_info: [],
    returns_info: null,
    promotions: [],
    free_returns: null,
    applied_discount: null,
    final_price: "",
    shipping_cost: null,
    shipping_cost_info: { ...DEFAULT_PRODUCT_PRICE },
};

export function getBuyOptionStickerType(
    product: ProductSerializer,
    buyOption: ProductBuyOptionsSerializer,
    cheapestOptionKey: number
): StickerType | undefined {
    const isCheapestPrice = cheapestOptionKey === buyOption.link_id;
    const isDesignerRetailer =
        buyOption.retailer_network?.toLowerCase() === product.designer.toLowerCase();

    if (isCheapestPrice) {
        return "lowestPrice";
    }

    if (isDesignerRetailer) {
        return "officialStore";
    }

    if (buyOption.is_icon) {
        return "checkoutOnLyst";
    }

    return undefined;
}

export function isSelectedSize(
    selectedSizeOption: ProductScreenSize,
    size: ProductScreenSize
): boolean {
    const userDefaultSchema = selectedSizeOption.user_default_schema;
    return userDefaultSchema &&
        size.localized_sizes &&
        selectedSizeOption.localized_sizes &&
        userDefaultSchema in size.localized_sizes &&
        userDefaultSchema in selectedSizeOption.localized_sizes
        ? size.localized_sizes[userDefaultSchema].display_size ===
              selectedSizeOption.localized_sizes[userDefaultSchema].display_size
        : size.raw_size === selectedSizeOption.raw_size;
}
