import {
    ANDROID_SIGNUP_CAPTURE_TYPES,
    AuthType,
    CaptureType,
} from "web/redux/ducks/customer-capture-overlay";
import { gettext } from "web/script/modules/django-i18n";
import url from "web/script/utils/url";

interface GetCopyDetailsOptions {
    name?: string;
    blockType?: string;
    browserMobile?: boolean;
}

interface GetCopyDetailReturn {
    submitText: string;
    title: string;
    subtitle: string;
}

export function getCopyDetails(
    captureType: string,
    options?: GetCopyDetailsOptions
): GetCopyDetailReturn {
    let submitText!: string;
    let title!: string;
    let subtitle!: string;

    switch (captureType) {
        case "pdp_show_more":
        case "feeds_show_more":
        case "feeds_product_card":
        case "signup_checkout_save":
            // "Continue"
            submitText = gettext("general.continue");
            // "Lyst is even better when you are a member. Login or register to save the products you love."
            title = gettext("member_signup.member_save_products.title");
            break;
        case "signup_du":
        case "signup_du_banner":
            submitText = gettext("account.register.return_lead_btn_follow_products_designer");
            title = gettext("account.register.follow_products_deals_designer.title", {
                designer_name: options?.name,
            });
            break;
        case "signup_su":
            submitText = gettext("account.register.return_lead_btn_follow_products_store");
            title = gettext("account.register.follow_products_deals_store.title", {
                retailer_name: options?.name,
            });
            break;
        case "signup_checkout_save":
        case "signup_pdp_save":
        case "signup_feed_save":
        case "signup_lead_save":
            if (options?.blockType === "hard_block_after_action") {
                submitText = gettext("account.register.sign_up_with_email");
                title = gettext("account.register.lead_return.title");
            } else if (options?.browserMobile) {
                submitText = gettext("general.continue");
                subtitle = gettext("member_signup.member_overlay.desc");
                title = gettext("member_signup.member_overlay.title_1");
            } else {
                submitText = gettext("account.register.save_product.cta");
                title = gettext("account.register.save_products_item_sale.title");
            }
            break;
        case "signup_oos_save":
            // Alternative phrase key title from an experiment:
            // "account.register.stock_alert.title" (Be the first to know when this is back.)
            submitText = gettext("account.register.save_product.cta");
            title = gettext("account.register.save_products_item_sale.title");
            break;
        case "size_stock_alert":
            submitText = gettext("general.continue");
            // We’ll let you know when this is back in stock in your size"
            title = gettext("member_signup.member_overlay.oos_size.title");
            break;
        case "stock_alert":
            submitText = gettext("general.alert_me");
            // We'll tell you the minute it's back.
            title = gettext("email_capture.stock_alert.title");
            break;
        case "stock_alert_lead_return":
            submitText = gettext("general.alert_me");
            // Not in stock anymore? We'll tell you the minute it's back
            title = gettext("email_capture.stock_alert.title_when_triggered_by_lead_link");
            break;
        case "signup_search":
            // Follow search
            submitText = gettext("account.register.return_lead_btn_follow_search");
            // Follow new search results for {search_terms}
            title = gettext("account.register.return_lead_title_follow_search", {
                search_terms: options?.name,
            });
            break;
        case "aspirer_acquisition_campaign":
            title = gettext("member_signup.modal.aspirer_acquisition.title");
            subtitle = gettext("member_signup.modal.aspirer_acquisition.subtitle");
            submitText = gettext("general.continue");
            break;
        case "wishlist_modal_experiment_in_stock":
            title = gettext("member_signup.modal.in_stock.title");
            submitText = gettext("general.continue");
            break;
        case "wishlist_modal_experiment_out_of_stock":
            title = gettext("member_signup.modal.out_of_stock.title");
            submitText = gettext("general.continue");
            break;
        case "wishlist_modal_experiment_on_sale":
            title = gettext("member_signup.modal.on_sale.title");
            submitText = gettext("general.continue");
            break;
    }

    return { submitText, title, subtitle };
}

export function getTypeDetails(captureType?: CaptureType): AuthType {
    switch (captureType) {
        case "size_stock_alert":
        case "signup_checkout_save":
        case "signup_pdp_save":
        case "signup_feed_save":
        case "stock_alert":
        case "stock_alert_lead_return":
        case "wishlist_modal_experiment_in_stock":
        case "wishlist_modal_experiment_out_of_stock":
        case "wishlist_modal_experiment_on_sale":
            return "save_for_later";
        case "signup_lead_save":
            return "save_for_later_lead_return";
        case "signup_oos_save":
            return "back_in_stock";
        case "signup_du":
            return "designer_update_lyst";
        case "signup_su":
            return "store_update_lyst";
        case "aspirer_acquisition_campaign":
            return "aspirer_acquisition_campaign";
    }
    return "normal";
}

export function generateSearchParams({ next, captureType, searchTerm, searchGender }): {
    searchTermParam: string | null;
    searchGenderParam: string | null;
    nextParam: string;
} {
    const parsedURL = url.parse(window.location.toString());
    // work out what the 'next' parameter should be
    parsedURL.searchParams.set("loggedin", "true");
    // avoid 'instant-notification' showing for these capture types
    if (!ANDROID_SIGNUP_CAPTURE_TYPES.includes(captureType as CaptureType)) {
        parsedURL.searchParams.set("area", "heart");
    }

    const path = next || parsedURL.path;
    const params = url.toQueryString(parsedURL.searchParams);
    const nextParam = next || `${path}${params && `?${params}`}`;
    const searchTermParam = searchTerm || parsedURL.searchParams.get("q");
    const searchGenderParam =
        searchGender ||
        parsedURL.searchParams.get("gender") ||
        parsedURL.searchParams.get("meta_gender");

    return { searchTermParam, searchGenderParam, nextParam };
}

export function shouldTruncateAnalyticsCategory(captureType: CaptureType): boolean {
    // Check if we should not truncate the event. TODO: Remove. See: https://lyst.atlassian.net/browse/BEAM-421
    const DO_NOT_TRUNCATE = [
        "aspirer_acquisition_campaign",
        "wishlist_modal_experiment_in_stock",
        "wishlist_modal_experiment_out_of_stock",
        "wishlist_modal_experiment_on_sale",
    ];

    return !DO_NOT_TRUNCATE.includes(captureType);
}
