import clsx from "clsx";
import SelectedCheckboxIcon from "img/checkout/icons/checkbox-selected.svg?inline";
import UnselectedCheckboxIcon from "img/checkout/icons/checkbox-unselected.svg?inline";
import React, { useState } from "react";
import Conditional from "web/react/components/conditional/conditional";
import { HStack } from "web/react/emo/h-stack";
import { HiddenVisually } from "web/react/emo/hidden-visually";
import { InlineErrorMessage } from "web/react/emo/inline-error-message";
import { ResponsiveTextStyle } from "web/react/emo/sprinkles.css";
import { Text } from "web/react/emo/text";
import { VStack } from "web/react/emo/v-stack";
import * as styles from "./checkbox.css";

export interface CheckboxProps extends Omit<React.HTMLProps<HTMLInputElement>, "label"> {
    name: string;
    label?: React.ReactNode;
    labelClassName?: string;
    error?: string;
    onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
    textStyle?: ResponsiveTextStyle;
}

export function Checkbox({
    label,
    labelClassName,
    error,
    onChange,
    textStyle = "body-3-small",
}: CheckboxProps): JSX.Element {
    const hasError = !!error;

    const [checked, setChecked] = useState(false);

    return (
        <VStack spacing="xxxs">
            <label>
                <HStack spacing="xxs">
                    <HiddenVisually>
                        <input
                            type="checkbox"
                            checked={checked}
                            aria-invalid={hasError}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                onChange?.(event);
                                setChecked(!checked);
                            }}
                        />
                    </HiddenVisually>
                    <div>{checked ? <SelectedCheckboxIcon /> : <UnselectedCheckboxIcon />}</div>
                    <Text textStyle={textStyle} className={clsx(styles.label, labelClassName)}>
                        {label}
                    </Text>
                </HStack>
            </label>
            <Conditional check={hasError}>
                <InlineErrorMessage>{error}</InlineErrorMessage>
            </Conditional>
        </VStack>
    );
}
