import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import environment from "web/script/modules/environment";

export const CAPTURE_TYPES = [
    "save_for_later",
    "signup_pdp_save",
    "signup_feed_save",
    "signup_checkout_save",
    "signup_lead_save",
    "signup_du",
    "signup_du_banner",
    "signup_su",
    "signup_search",
    "stock_alert",
    "stock_alert_lead_return",
    "size_stock_alert",
    "signup_oos_save",
    "qr_code",
    "pdp_show_more",
    "feeds_show_more",
    "signup_banner",
    "related_products",
    "feeds_product_card",
    "header_logo",
    "opt_in",
    "my_sizes_adoption_experiment",
    "facebook_landing_campaign",
    "aspirer_acquisition_campaign",
    "wishlist_modal_experiment_in_stock",
    "wishlist_modal_experiment_out_of_stock",
    "wishlist_modal_experiment_on_sale",
] as const;

export type CaptureType = (typeof CAPTURE_TYPES)[number];

export const AUTH_TYPES = [
    "normal",
    "save_for_later",
    "save_for_later_lead_return",
    "save_for_later_landing_page",
    "saved_search",
    "back_in_stock",
    "designer_update",
    "designer_update_lyst",
    "normal_hp_wall",
    "save_for_later_recent",
    "feeds_show_more",
    "signup_banner",
    "related_product",
    "store_update_lyst",
    "feeds_product_card",
    "aspirer_acquisition_campaign",
] as const;

export type AuthType = (typeof AUTH_TYPES)[number];

export const ANDROID_SIGNUP_CAPTURE_TYPES: Readonly<CaptureType[]> = [
    "feeds_show_more",
    "signup_banner",
    "related_products",
    "signup_oos_save",
];

interface CustomerCaptureOverlayState {
    captureType?: CaptureType;
    designerId?: string;
    designerName?: string;
    next?: string;
    open: boolean;
    productId?: string | number | null;
    searchGender?: string;
    searchTerm?: string;
    productImageUrl?: string;
    branchLink?: string;
    appDeeplinkPath?: string;
    onClose?: () => void;
    onCtaClick?: () => void;
    blockType?: string;
    analyticsEventCategory?: string;
    analyticsEventLabel?: string;
    componentType?: string;
    titleKey?: string;
    ctaKey?: string;
    imageSrc?: string;
    showSocialAuth?: boolean;
    legalTextKey?: string;
    showMarketingCheckbox?: boolean;
}

const initialState = {
    open: false,
} as CustomerCaptureOverlayState;

const isCheckoutPage = environment.get("pageType") === "checkout";

const customerCaptureOverlaySlice = createSlice({
    name: "customerCaptureOverlay",
    initialState,
    reducers: {
        openCustomerCaptureOverlay(
            state,
            action: PayloadAction<Omit<CustomerCaptureOverlayState, "open">>
        ) {
            return { ...action.payload, open: !isCheckoutPage };
        },
        closeCustomerCaptureOverlay(state) {
            state.onClose?.();

            return { open: false };
        },
    },
});

// Extract the action creators object and the reducer
const { actions, reducer } = customerCaptureOverlaySlice;
// Extract and export each action creator by name
export const { openCustomerCaptureOverlay, closeCustomerCaptureOverlay } = actions;
// Export the reducer, either as a default or named export
export default reducer;
