import clsx from "clsx";
import React from "react";
import * as styles from "./svg-icon.css";

export type SVGIconName =
    | "account-icon"
    | "alert-new"
    | "alerts-icon"
    | "apple-icon"
    | "arrow-emo"
    | "arrow-down"
    | "arrow-icon"
    | "arrow-left"
    | "arrow-right"
    | "basket-icon"
    | "basket-new"
    | "bell"
    | "bell-empty"
    | "bell-nav"
    | "bell-nav-filled"
    | "big-arrow-pdp-emo"
    | "cart"
    | "chevron"
    | "chevron-medium"
    | "chevron-thin"
    | "chevron-emo"
    | "clear"
    | "close"
    | "cross"
    | "cross-thin"
    | "discount"
    | "emo-basket"
    | "emo-star"
    | "emo-tick"
    | "error"
    | "expand"
    | "expand-legacy"
    | "external-link"
    | "facebook"
    | "facebook-icon"
    | "fat-arrow"
    | "filters"
    | "google-icon"
    | "heart"
    | "heart-broken"
    | "heart-empty"
    | "heart-icon-empty"
    | "heart-icon-nav"
    | "heart-icon-nav-filled"
    | "heart-icon-filled"
    | "heart-pressed"
    | "heart-unpressed"
    | "hyperlink"
    | "icon-bulb"
    | "icon-smiley"
    | "info-icon"
    | "instagram"
    | "lyst-app-icon"
    | "lysts-control"
    | "lysts-recommendations"
    | "lysts-search"
    | "magnify"
    | "mail-plane"
    | "minus"
    | "padlock"
    | "pinterest"
    | "plus"
    | "plus-bag"
    | "plus-thin"
    | "preview-icon"
    | "price-drop"
    | "promo-tag"
    | "qr-icon"
    | "round-checkbox"
    | "round-checkbox-bold"
    | "round-checkbox-checked"
    | "round-chevron"
    | "round-minus"
    | "round-plus"
    | "round-radiobutton"
    | "round-radiobutton-checked"
    | "round-select-marker"
    | "saved-search"
    | "saved-search-bookmark"
    | "saved-search-empty"
    | "search"
    | "search-emo"
    | "search-light-grey"
    | "searches-icon"
    | "searches-icon-empty"
    | "searches-icon-filled"
    | "shoppingbag-icon"
    | "show-off"
    | "show-on"
    | "small-arrow"
    | "sort"
    | "square-chevron"
    | "square-chevron-thin"
    | "stars"
    | "stopwatch"
    | "tag"
    | "tag-fill"
    | "tag-outline"
    | "tap-hand"
    | "tick"
    | "tick-new"
    | "truck"
    | "twitter"
    | "user"
    | "user-icon"
    | "user-icon-emo"
    | "value-proposition-search"
    | "value-proposition-shopping-bag"
    | "value-proposition-star"
    | "warning-icon"
    | "LEGACY-heart-pressed"
    | "LEGACY-heart-unpressed";

interface SVGIconProps {
    name: SVGIconName;
    className?: string;
    overrideStyle?: boolean;
    title?: string;
}

interface SVGIconRotateProps extends SVGIconProps {
    rotate?: number;
}

interface SVGIconFlipProps extends SVGIconProps {
    flip?: boolean;
}

export function SVGIcon({
    name,
    className,
    overrideStyle,
    title,
    ...props
}: SVGIconRotateProps | SVGIconFlipProps): React.ReactElement {
    let inlineStyles = {};
    if ("rotate" in props) {
        inlineStyles["transform"] = `rotate(${props.rotate}deg)`;
    } else if ("flip" in props) {
        inlineStyles["transform"] = "rotateY(180deg)";
    }

    return (
        <svg
            data-testid="icon"
            style={inlineStyles}
            aria-hidden="true"
            className={clsx(!overrideStyle && styles.icon, `svg-icon-${name}`, className)}
        >
            {title && <title>{title}</title>}
            <use xlinkHref={`#${name}`} />
        </svg>
    );
}

export default SVGIcon;
