import { useEffect, useState } from "react";
import { useHistoryWatcher } from "web/react/hooks/use-history-watcher/use-history-watcher";
import globals from "web/script/modules/globals";
import history, { BrowserHistoryEvent } from "web/script/utils/history";
import storage from "web/script/utils/storage";

export function useUrlData(): BrowserHistoryEvent | null {
    // urlData will be undefined when the component is mounted
    // because it represents the History onchange events
    // which are happening only in navigation
    const _urlData = useHistoryWatcher();
    const [urlData, setUrlData] = useState<BrowserHistoryEvent | null>(null);

    useEffect(() => {
        storage.set("initialPageURL", globals.window.location.href, true);
        if (globals.window.location.hash.startsWith("#slug=")) {
            storage.set("overlayOnInitialPageLoad", true, true);
        }
    }, []);

    useEffect(() => {
        let currentState;

        try {
            currentState = history.getCurrentState();
        } catch {
            currentState = null;
        }

        setUrlData(
            _urlData ??
                ({
                    newURL: new URL(globals.window.location.href),
                    oldURL: new URL(
                        globals.window.location.href.replace(globals.window.location.hash, "")
                    ),
                    // We need the initial state as well
                    state: currentState,
                    queryChanges: [],
                } as BrowserHistoryEvent)
        );
    }, [_urlData]);

    return urlData;
}
