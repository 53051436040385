import React from "react";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { HStack } from "web/react/emo/h-stack";
import { Text } from "web/react/emo/text/text";
import { VStack } from "web/react/emo/v-stack";
import { gettext } from "web/script/modules/django-i18n";
import styles from "./user-email-card.module.css";

export function UserEmailCard({ email, onClick }): JSX.Element {
    return (
        <HStack spacing={"xs"}>
            <div className={styles.iconWrapper}>
                <SVGIcon name={"account-icon"} />
            </div>
            <VStack spacing={"xxxs"}>
                <Text as={"span"} textStyle={"body-3"} color={"secondary"}>
                    {email}
                </Text>
                <button onClick={onClick} className={styles.ctaButton}>
                    <Text as="span" textStyle={"callout-v2"} upperCase>
                        {gettext("general.change")}
                    </Text>
                </button>
            </VStack>
        </HStack>
    );
}
