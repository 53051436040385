import { useEffect, useRef } from "react";
import { getOverlayDecision } from "web/react/components/product-overlay/utils";
import { useFeedFetch } from "web/react/hooks/use-feed-fetch/use-feed-fetch";
import { useLandingContent } from "web/react/hooks/use-landing-content/use-landing-content";
import { useUrlData } from "web/react/hooks/use-url-data/use-url-data";
import { fetchMoreFeedItems } from "web/redux/api/pagination";
import analytics from "web/script/analytics/analytics";
import globals from "web/script/modules/globals";
import { BrowserHistoryEvent } from "web/script/utils/history";

const initialRefs = {
    currentUrl: "",
    isFetching: false,
    initialPageNum: 1,
    renderedPages: [1],
};

export function useProductFeed(): void {
    const refs = useRef(initialRefs);

    const urlData = useUrlData();
    const showLandingContent = useLandingContent();
    const { fetchFeed } = useFeedFetch();
    const overlayDecision = getOverlayDecision("feed");

    // Set the initial page number, the rendered pages and the current url on component mount
    useEffect(() => {
        refs.current.initialPageNum = _getPageNumParam(globals.window.location.href);
        refs.current.renderedPages = [_getPageNumParam(globals.window.location.href)];
        const parsedUrl = new URL(globals.window.location.href);
        refs.current.currentUrl = parsedUrl.pathname + parsedUrl.search;

        if (globals.window.history.scrollRestoration) {
            globals.window.history.scrollRestoration = "manual";
        }
    }, []);

    // Remove the landing content if needed
    useEffect(() => {
        if (!showLandingContent) {
            document
                .querySelectorAll(".landing-content")
                .forEach((el) => el.parentNode?.removeChild(el));
        }
    }, [showLandingContent]);

    useEffect(() => {
        // Don't run if the urlData is not available yet
        // or if we are fetching a feed
        if (!urlData || refs.current.isFetching) {
            return;
        }

        // Don't do anything if the related products overlay is opening
        if (urlData.state?.overlay === "related-products-overlay") {
            return;
        }

        // If the urlData change indicates opening product overlay then bail out
        const productOverlayDecision = overlayDecision(urlData, false);

        if (productOverlayDecision) {
            return;
        }

        // Update feed
        const { newURL, oldURL, queryChanges } = urlData;
        const newPageNumber = _getPageNumParam(newURL);

        const isSamePath = newURL.pathname === oldURL?.pathname;
        const isOnlyPageChange =
            isSamePath && queryChanges?.length === 1 && queryChanges?.[0].key === "page";

        // If we are on the same path try loading next page if the page number changed in the query
        if (isOnlyPageChange) {
            if (
                refs.current.renderedPages.includes(newPageNumber) &&
                refs.current.renderedPages.length > 1
            ) {
                return;
            }

            // Append next set of page results
            if (_isNextPage(newPageNumber, refs.current.renderedPages)) {
                refs.current.renderedPages = [...refs.current.renderedPages, newPageNumber];
                refs.current.isFetching = true;
                fetchMoreFeedItems(newURL.pathname + newURL.search)
                    .catch(() => {
                        globals.window.location.reload();
                    })
                    .finally(() => {
                        refs.current.isFetching = false;
                    });
                return;
            }
        }

        refs.current.initialPageNum = newPageNumber;
        refs.current.renderedPages = [newPageNumber];

        const feedUrl = newURL.pathname + newURL.search;

        if (refs.current.currentUrl === feedUrl) {
            return;
        }

        refs.current.isFetching = true;
        fetchFeed(feedUrl)
            .then(() => {
                _sendPageView(urlData);
                refs.current.currentUrl = feedUrl;
            })
            .finally(() => {
                refs.current.isFetching = false;
            });
    }, [urlData, fetchFeed, overlayDecision]);
}

// Don't send pageView events if you are going backwards or forwards.
// This makes our analytics more consistent but also happens to work around
// a problem in the reffer tracking in the analytics module that results
// in pages having themselves as a referrer currently.
function _sendPageView(urlData: BrowserHistoryEvent): void {
    if (
        (urlData.state &&
            urlData.oldState &&
            urlData.state.pageViewId === urlData.oldState.pageViewId) ||
        urlData.eventType === "popstate"
    ) {
        return;
    }

    analytics.pageView();
}

function _getPageNumParam(pageUrl: string | URL): number {
    const parsedUrl = new URL(pageUrl, globals.window.location.href);
    const page = parsedUrl.searchParams.get("page");

    if (!page) {
        return 1;
    }

    return parseInt(page, 10);
}

function _isNextPage(newPageNumber: number, renderedPages: number[]): boolean {
    const lastRenderedPage = renderedPages[renderedPages.length - 1];

    if (newPageNumber - lastRenderedPage === 1) {
        return true;
    }

    return false;
}
