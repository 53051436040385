import clsx from "clsx";
import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import BrandAdLoader from "web/react/components/brand-ad-loader/brand-ad-loader";
import CuratedProductsCard from "web/react/components/curated-products-card/curated-products-card";
import { FeedNoResults } from "web/react/components/feed-no-results/feed-no-results";
import { FeedsShowMore } from "web/react/components/feeds-show-more/feeds-show-more";
import { KeylineGrid, KeylineGridItem } from "web/react/components/keyline-grid";
import { OmnibusDirectiveBanner } from "web/react/components/omnibus-directive-banner/omnibus-directive-banner";
import { OmnibusFashionClipBanner } from "web/react/components/omnibus-fashionclip-banner/omnibus-fashionclip-banner";
import { ProductDetails } from "web/react/components/product-card/react/emo";
import ProductCard from "web/react/components/product-card/react/product-card";
import { ExpandableText } from "web/react/emo/expandable-text/expandable-text";
import { HStack } from "web/react/emo/h-stack";
import { ProductCardPrice, ProductCardWishlist, ProductImage } from "web/react/emo/product-card";
import { VStack } from "web/react/emo/v-stack";
import { useDomViewport } from "web/react/hooks/use-dom-viewport/use-dom-viewport";
import { useInitialReduxState } from "web/react/hooks/use-inital-redux-state/use-initial-redux-state";
import { useProductFeed } from "web/react/hooks/use-product-feed/use-product-feed";
import { FeedPage } from "web/react/pages/feed/utils";
import { setFeedData } from "web/redux/ducks/feed";
import { setAll as setShowMoreAll } from "web/redux/ducks/feed-show-more";
import { ReduxStoreState } from "web/redux/store";
import analytics from "web/script/analytics/analytics";
import { gettext } from "web/script/modules/django-i18n";
import environment from "web/script/modules/environment";
import globals from "web/script/modules/globals";
import { canUseMembership, shouldShowOmnibusPages } from "web/script/modules/utils";
import { getInitialFeedProductIds } from "web/script/utils/factories";
import {
    BrandAdSerializer,
    CuratedProductsLinksSerializer,
    ProductCardSerializer,
    ProductFeedSerializer,
} from "web/types/serializers";
import * as styles from "./feed-grid.css";

interface FeedGridConnected {
    description: string | null;
    inFeedBrandAd?: BrandAdSerializer | null;
}

export interface FeedGridProps extends FeedGridConnected {
    data: Pick<
        ProductFeedSerializer,
        | "feed_items"
        | "type"
        | "pre_filters"
        | "curated_products"
        | "show_more_button"
        | "lyst_name"
        | "empty_feed_link"
        | "empty_message"
    >;
    feedType?: FeedPage;
}

export function FeedGrid({
    data,
    description,
    feedType,
    inFeedBrandAd,
}: FeedGridProps): JSX.Element {
    // TODO: move this to a hook based approach. We could reduce this weird set/get logic. Would require a rethink of feeds logic on the FE.
    useInitialReduxState([
        [setFeedData, data],
        [setShowMoreAll, data.show_more_button],
    ]);

    const feedData = useSelector((state: ReduxStoreState) => state.feed.data);
    const feedGridData = feedData.feed_items.length ? feedData : data;

    if (!feedGridData.feed_items.length) {
        return <FeedNoResults data={feedGridData} feedType={feedType} />;
    }

    return (
        <div data-testid="feed-grid">
            {shouldShowOmnibusPages() && <OmnibusDirectiveBanner />}
            {environment.getFeature("cw_feedback_banner") && <OmnibusFashionClipBanner />}
            <FeedGridConnected description={description} inFeedBrandAd={inFeedBrandAd} />
        </div>
    );
}

function FeedGridConnected({ description, inFeedBrandAd }: FeedGridConnected): JSX.Element | null {
    const {
        data: { feed_items: feedItems, type, curated_products: curatedProducts, lyst_name: title },
        ui: { isFetching },
    } = useSelector((state: ReduxStoreState) => state.feed);
    useProductFeed();

    return (
        <>
            <FeedGridInner
                feedItems={feedItems}
                curatedProducts={curatedProducts}
                isFetching={isFetching}
                feedType={type || ""}
                description={description}
                title={title}
                inFeedBrandAd={inFeedBrandAd}
            />
            <FeedsShowMore />
        </>
    );
}

interface FeedProductCardProps {
    product: ProductCardSerializer;
    feedType: FeedPage | string;
}

export function FeedProductCard({ product, feedType }: FeedProductCardProps): JSX.Element {
    return (
        <ProductCard product={product} dataTestId="product-card" feedType={feedType}>
            <VStack spacing="xxs">
                <ProductImage feedType={feedType} shouldRenderLeadLinkInImage />
                <VStack spacing="xxxs">
                    <HStack spacing="none" justify="space-between">
                        <ProductCardPrice size="sm" />
                        {canUseMembership() && (
                            <ProductCardWishlist isSavedForLater={product.is_saved_for_later} />
                        )}
                    </HStack>
                    <ProductDetails feedType={feedType} />
                </VStack>
            </VStack>
        </ProductCard>
    );
}

export function getProductIdFromUrl(): number | null {
    const productId = new URLSearchParams(globals?.window?.location?.search).get("productId");
    return productId ? Number(productId) : null;
}

function getCuratedProductCTA(
    curatedProducts: ProductFeedSerializer["curated_products"],
    i: number
): CuratedProductsLinksSerializer | null {
    if (curatedProducts && i % 14 === 0 && curatedProducts[i / 14 - 1]) {
        return curatedProducts[i / 14 - 1];
    }
    return null;
}

function getBrandAdPosition(
    brandAd: FeedGridInnerProps["inFeedBrandAd"],
    feedItems: ProductFeedSerializer["feed_items"],
    isMobileViewport: boolean,
    isTabletViewport: boolean
): null | 3 | 5 | 7 {
    const itemCount = feedItems.length;

    if (!brandAd) {
        return null;
    }

    if (isMobileViewport) {
        return itemCount >= 8 ? 3 : null;
    }

    if (isTabletViewport) {
        return itemCount >= 12 ? 5 : null;
    }

    return itemCount >= 16 ? 7 : null;
}

interface FeedGridInnerProps {
    isFetching: boolean;
    feedItems: ProductFeedSerializer["feed_items"];
    feedType: FeedPage | string;
    curatedProducts?: ProductFeedSerializer["curated_products"];
    description?: string | null;
    title?: string | null;
    inFeedBrandAd?: BrandAdSerializer | null;
}

function FeedGridInner({
    feedItems,
    isFetching,
    feedType,
    curatedProducts,
    description,
    title,
    inFeedBrandAd,
}: FeedGridInnerProps): JSX.Element {
    const productIds = getInitialFeedProductIds(feedItems);

    useEffect(() => {
        analytics.event("feed", "view", "feed_product_ids", true, {
            ids: productIds,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(productIds)]);

    const { isMobileViewport, isTabletViewport } = useDomViewport();
    const productId = getProductIdFromUrl();
    const brandAdPosition = getBrandAdPosition(
        inFeedBrandAd,
        feedItems,
        isMobileViewport,
        isTabletViewport
    );

    return (
        <KeylineGrid className={clsx(isFetching && styles.loading)}>
            {feedItems.map(({ product_card: product }, i) => {
                const cta = getCuratedProductCTA(curatedProducts, i);
                // On mobile screens, position the description in the second row
                const showMobileDescription = !!(i === 1 && description);

                return (
                    <Fragment key={`${product.uid} ${product.link_id}`}>
                        <KeylineGridItem scrollIntoView={productId === product.id}>
                            <FeedProductCard product={product} feedType={feedType} />
                        </KeylineGridItem>

                        {showMobileDescription && (
                            <KeylineGridItem
                                colspan="full"
                                className={clsx("hidden-desktop", "hidden-tablet-only")}
                            >
                                <ExpandableText
                                    title={`${gettext("general.about_retailer", {
                                        retailer_name: title,
                                    })}:`}
                                    variant="box"
                                    lineLimit={3}
                                    analyticsCategory="feed-description"
                                    dangerouslySetInnerHtml
                                >
                                    {description}
                                </ExpandableText>
                            </KeylineGridItem>
                        )}

                        {brandAdPosition === i && (
                            <KeylineGridItem colspan="full">
                                <BrandAdLoader {...(inFeedBrandAd as BrandAdSerializer)} />
                            </KeylineGridItem>
                        )}

                        {cta && (
                            <KeylineGridItem colspan={2}>
                                <CuratedProductsCard links={cta.links} />
                            </KeylineGridItem>
                        )}
                    </Fragment>
                );
            })}
        </KeylineGrid>
    );
}
