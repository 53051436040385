import { appendCuratedProducts, appendFeedItems } from "web/redux/ducks/feed";
import { setAll as setShowMoreAll } from "web/redux/ducks/feed-show-more";
import store from "web/redux/store";
import rothko from "web/script/modules/rothko";
import { ProductFeedSerializer } from "web/types/serializers";

const client = new rothko.Rothko("modules/product_feed");

export function fetchMoreFeedItems(url: string): Promise<void> {
    return client.fetch({ url }).then((data: ProductFeedSerializer) => {
        store.dispatch(appendFeedItems(data.feed_items));

        if (data.curated_products) {
            store.dispatch(appendCuratedProducts(data.curated_products));
        }

        if (data.show_more_button) {
            store.dispatch(
                setShowMoreAll({
                    ...data.show_more_button,
                    visible_product_count: store.getState().feed.data.feed_items.length,
                })
            );
        }
    });
}
