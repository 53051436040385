import clsx from "clsx";
import React, { lazy, Suspense, useEffect, useState } from "react";
import {
    ButtonType,
    Button as LegacyButton,
} from "web/react/components/__LEGACY__/LEGACY_button/button";
import LoadingSpinner from "web/react/components/loading-spinner/loading-spinner";
import SVGIcon from "web/react/components/svg-icon/svg-icon";
import { Button } from "web/react/emo/button";
import { usePrevious } from "web/react/hooks/use-previous/use-previous";
import { gettext } from "web/script/modules/django-i18n";
import * as styles from "./save-for-later.css";

const LEGACYHeartAnimation = lazy(
    () =>
        import(
            "web/react/components/lottie-animations/LEGACY-heart-animation/LEGACY-heart-animation"
        )
);

const HeartAnimation = lazy(
    () => import("web/react/components/lottie-animations/heart-animation/heart-animation")
);

interface SaveForLaterProps {
    isSaved: boolean;
    onClick?: () => void;
    isSaving?: boolean;
    className?: string;
    cta?: string;
    bingTraffic?: boolean;
    showExpressCheckout?: boolean;
    variant?: "emotional";
}

export function SaveForLaterIcon({
    onClick,
    variant,
    isSaved,
    isSaving,
    className,
}: SaveForLaterProps): React.ReactNode {
    const [isAnimating, setIsAnimating] = useState(false);
    const [removeHover, setRemoveHover] = useState(false);
    const [saved, setSaved] = useState(isSaved);
    const previousIsSaved = usePrevious(isSaved);
    const showNewIconDesign = variant === "emotional";

    function loadAnimation(): void {
        // Dynamically import heart animation when user hovers...
        if (showNewIconDesign) {
            import("web/react/components/lottie-animations/heart-animation/heart-animation");
        } else {
            import(
                "web/react/components/lottie-animations/LEGACY-heart-animation/LEGACY-heart-animation"
            );
        }
    }

    // Optimistic update when saving so we don't wait for the response from server
    useEffect(() => {
        if (!previousIsSaved && isSaving) {
            setIsAnimating(true);
        }
    }, [previousIsSaved, isSaving]);

    // Sync the local state with the global
    useEffect(() => {
        setSaved(isSaved);
    }, [isSaved]);

    return (
        <button
            onClick={() => {
                if (isAnimating) {
                    setIsAnimating(false);
                }

                // Optimistic update
                // The useEffect hook above will handle cases
                // where the local state diverges with the global
                // so it is in sync
                // With this we want to avoid the slight delay in changing the icon status
                if (saved) {
                    setSaved(false);
                    setRemoveHover(true);
                }

                onClick?.();
            }}
            className={clsx(styles.icon, !showNewIconDesign && styles.oldIcon, className, {
                [styles.iconSaved]: saved,
                nohover: removeHover,
            })}
            onMouseEnter={loadAnimation}
            onMouseLeave={() => setRemoveHover(false)}
        >
            {showNewIconDesign ? (
                <span className={styles.heartWithAnimation}>
                    {isAnimating ? (
                        <Suspense fallback={<LoadingSpinner />}>
                            <span className={styles.animatedEmoHeart}>
                                <HeartAnimation />
                            </span>
                        </Suspense>
                    ) : (
                        <>
                            <SVGIcon
                                name={"heart-pressed"}
                                className={clsx(styles.svgEmoIcon, styles.heartPressed)}
                            />
                            {!saved && (
                                <SVGIcon
                                    name={"heart-unpressed"}
                                    className={clsx(styles.svgEmoIcon, styles.heartUnpressed)}
                                />
                            )}
                        </>
                    )}
                </span>
            ) : (
                <>
                    <SVGIcon
                        name={saved ? "LEGACY-heart-pressed" : "LEGACY-heart-unpressed"}
                        className={styles.svgIcon}
                    />
                    {isAnimating && (
                        <Suspense fallback={<LoadingSpinner />}>
                            <div className={styles.animatedHeart}>
                                <LEGACYHeartAnimation
                                    onComplete={() => {
                                        setIsAnimating(false);
                                    }}
                                    onAnimationStart={() => {
                                        setIsAnimating(true);
                                    }}
                                />
                            </div>
                        </Suspense>
                    )}
                </>
            )}
        </button>
    );
}

export function LEGACYSaveForLaterSmall({
    onClick,
    isSaved,
    className,
}: SaveForLaterProps): React.ReactNode {
    return (
        <button
            onClick={onClick}
            className={clsx(styles.small, className, {
                [styles.smallSaved]: isSaved,
            })}
        >
            <SVGIcon name="heart" className={styles.svgIcon} />
        </button>
    );
}

function getCTA(isSaved: boolean): string {
    if (isSaved) {
        return gettext("in_stock_product.saved");
    } else {
        return gettext("save_for_later.cta.variant_b");
    }
}

export function LEGACYSaveForLaterNormal({
    onClick,
    isSaved,
    isSaving,
    className,
    cta,
    showExpressCheckout,
    bingTraffic,
}: SaveForLaterProps): React.ReactNode {
    let buttonStyle = "secondary";

    if (showExpressCheckout && bingTraffic === false) {
        buttonStyle = "cta";
    }

    return (
        <LegacyButton
            styleType={isSaved ? ("temp-grey" as ButtonType) : (buttonStyle as ButtonType)}
            onClick={onClick}
            className={className}
            fullWidth
            disabled={isSaving}
        >
            <SVGIcon className={styles.svg} name={isSaved ? "heart" : "heart-empty"} />{" "}
            <span>{cta ? cta : getCTA(isSaved)}</span>
        </LegacyButton>
    );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function SaveForLaterCTA({ onClick, cta, isSaved }: SaveForLaterProps): React.ReactNode {
    // TODO CW-622: add "isActive" to button props, relies on `isSaved`
    return (
        <Button
            title={cta as string}
            size="sm"
            variant="secondary"
            onClick={onClick}
            // isActive={isSaved}
        />
    );
}

export function LEGACYSaveForLaterStockAlert({
    onClick,
    isSaved,
    isSaving,
    className,
    cta,
}: SaveForLaterProps): React.ReactNode {
    let ctaText;

    if (isSaved) {
        ctaText = getCTA(isSaved);
    } else {
        ctaText = cta ? cta : getCTA(isSaved);
    }

    return (
        <LegacyButton
            styleType={isSaved ? "temp-grey" : "cta"}
            onClick={onClick}
            className={className}
            disabled={isSaving}
        >
            {isSaved ? (
                <SVGIcon className={styles.svgSaved} name={"bell"} />
            ) : (
                <SVGIcon className={styles.svg} name={"bell-empty"} />
            )}
            <span>{ctaText}</span>
        </LegacyButton>
    );
}

export function LEGACYSaveForLaterTick({
    onClick,
    isSaved,
    isSaving,
    className,
    cta,
}: SaveForLaterProps): React.ReactNode {
    let ctaText;

    if (isSaved) {
        ctaText = gettext("oos_product.signup_bis_alert_set.button");
    } else {
        ctaText = cta ? cta : getCTA(isSaved);
    }

    return (
        <LegacyButton
            styleType={isSaved ? "temp-gold" : "cta"}
            onClick={onClick}
            className={className}
            disabled={isSaving}
        >
            {isSaved && <SVGIcon className={styles.svgSavedTick} name="tick" />}
            <span>{ctaText}</span>
        </LegacyButton>
    );
}

// As a part of the redesign work, we should make an effort to
// only use the "cta" or "icon" variants of the S4L button. Other
// variants have been marked as "LEGACY" with the aim of removing once
// the redesign is complete. If the "cta" variant needs expanding on,
// please add to that component and only create new variants if absolutely
// necessary.
const BUTTON_TYPES = {
    cta: SaveForLaterCTA,
    icon: SaveForLaterIcon,
    LEGACY_normal: LEGACYSaveForLaterNormal,
    LEGACY_small: LEGACYSaveForLaterSmall,
    LEGACY_stockAlert: LEGACYSaveForLaterStockAlert,
    LEGACY_tick: LEGACYSaveForLaterTick,
};

function SaveForLater({
    type,
    ...props
}: SaveForLaterProps & {
    type: "cta" | "icon" | "LEGACY_normal" | "LEGACY_small" | "LEGACY_stockAlert" | "LEGACY_tick";
}): React.ReactNode {
    const Component = BUTTON_TYPES[type];

    if (!Component) {
        throw new Error(`Invalid button type ${type}!`);
    }

    return <Component {...props} />;
}

export default SaveForLater;
