import React from "react";
import { useProductCardContext } from "web/react/components/product-card/react";
import SaveForLaterButton, {
    ProductStatus,
} from "web/react/components/save-for-later-button/save-for-later-button";
import userProfiler from "web/script/modules/userprofiler";
import * as styles from "./product-card-wishlist.css";

interface ProductCardWishlistProps {
    isAffiliate?: boolean;
    isSavedForLater: boolean;
    beforeOnClick?: (any?) => void;
}

export function ProductCardWishlist({
    isAffiliate,
    isSavedForLater,
    beforeOnClick,
}: ProductCardWishlistProps): React.ReactElement | null {
    const {
        id,
        image_url: imageURL,
        app_deeplink_path: appDeepLinkPath,
        in_stock: inStock,
        sale_discount: saleDiscount,
    } = useProductCardContext();

    const productStatus: ProductStatus = !inStock
        ? "out_of_stock"
        : saleDiscount
        ? "on_sale"
        : "in_stock";

    return (
        <span className={styles.wishlist}>
            <SaveForLaterButton
                type="icon"
                isLoggedIn={userProfiler.isLoggedIn()}
                pageSource="feed"
                productId={id.toString()}
                productImageURL={imageURL?.toString()}
                productStatus={productStatus}
                isAffiliate={isAffiliate}
                appDeeplinkPath={appDeepLinkPath}
                variant={"emotional"}
                initialIsSaved={isSavedForLater}
                beforeOnClick={beforeOnClick}
            />
        </span>
    );
}
