import React, { useState } from "react";
import { MemoryRouter, Route, Routes, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { ValuePropositionAnimation } from "web/react/components/value-proposition-animation/";
import { View } from "web/react/emo/view";
import { AuthType } from "web/redux/ducks/customer-capture-overlay";
import { AuthFormModuleSerializer } from "web/types/serializers";
import { ForgotPasswordEmailSentScreen } from "./forgot-password-email-sent-screen";
import { ForgotPasswordScreen } from "./forgot-password-screen";
import { InitialScreen } from "./initial-screen";
import { LoginEmailSentScreen } from "./login-email-sent-screen";
import { LoginScreen } from "./login-screen";
import * as styles from "./signup-or-login-page.css";
import { SignupScreen } from "./signup-screen";

function SignupOrLoginRoutes({ ...props }: SignupOrLoginPageProps): JSX.Element {
    const [email, setEmail] = useState("");
    const location = useLocation();

    return (
        <TransitionGroup className={styles.transition}>
            <CSSTransition
                key={location.key}
                classNames={{
                    enter: styles.enter,
                    enterActive: styles.enterActive,
                    exit: styles.exit,
                    exitActive: styles.exitActive,
                }}
                timeout={{ enter: 1000, exit: 500 }}
                mountOnEnter
                unmountOnExit
            >
                <View width="full">
                    <Routes location={location}>
                        <Route
                            index
                            element={
                                <InitialScreen {...props} onSuccess={setEmail} email={email} />
                            }
                        />
                        <Route path="/signup" element={<SignupScreen email={email} />} />
                        <Route path="/login" element={<LoginScreen email={email} />} />
                        <Route
                            path="/login/email-sent"
                            element={<LoginEmailSentScreen email={email} />}
                        />
                        <Route
                            path="/forgot-password"
                            element={<ForgotPasswordScreen onSuccess={setEmail} email={email} />}
                        />
                        <Route
                            path="/forgot-password/email-sent"
                            element={<ForgotPasswordEmailSentScreen email={email} />}
                        />
                    </Routes>
                </View>
            </CSSTransition>
        </TransitionGroup>
    );
}

export interface SignupOrLoginPageProps {
    formData: AuthFormModuleSerializer;
    authType: AuthType;
    nextUrl: string;
    reason: string;
    productId: string;
    designerId: string;
    searchQuery: string;
    searchGender: string;
}

export function SignupOrLoginPage({ ...props }: SignupOrLoginPageProps): JSX.Element {
    return (
        <div className={styles.wrapper}>
            <div className={styles.layout}>
                <div className={styles.formContainer}>
                    <MemoryRouter>
                        <SignupOrLoginRoutes {...props} />
                    </MemoryRouter>
                </div>
                <div className={styles.imageContainer}>
                    <ValuePropositionAnimation />
                </div>
            </div>
        </div>
    );
}
