/** @module components/lyst-app */
import baustein from "baustein";
import _debounce from "lodash/debounce";
import { watchState } from "web/redux/state-watcher";
import analytics from "web/script/analytics/analytics";
import "web/script/components/background-banner";
import ShippingAndReturnsOverlay from "web/script/components/overlays/shipping-and-returns-overlay";
// Footer
import "web/script/components/tracked-link";
import environment from "web/script/modules/environment";
import globals from "web/script/modules/globals";
import requester from "web/script/modules/requester";
import Stack from "web/script/modules/stack";
import alerts from "web/script/utils/alerts";
import browser from "web/script/utils/browser";
import globalEvents from "web/script/utils/global-events";
import logging from "web/script/utils/logging";
import url from "web/script/utils/url";

const LYST_APP_CALLOUT_SHOWN_MODIFIER = "lyst-app--callout-shown";
const APP_HEADER_COMPONENT = "app-header-main";

/**
 * This component encapsulates the entire lyst web app. It handles page load set up, any
 * subsequent client side navigation, and ideally also will manage the lifecycle of overlays.
 * @constructor
 * @alias module:components/lyst-app
 * @extends module:baustein
 */
export default baustein.register(
    "lyst-app",
    /** @lends module:components/lyst-app.prototype */
    {
        defaultOptions: {
            surveyEnabled: false,
            exitOverlaySource: "",
            exitOverlayUrl: "",
        },

        overlays: ["base-overlay", "country-overlay", "editing-overlay", "product-overlay"],

        membershipPages: ["sign_in", "new_signup", "signup", "signup form"],

        setupEvents(add) {
            add("overlayCloseIntent", this._onOverlayCloseIntent);
            add("overlayClose", this._onOverlayClose);
            add("oosItemFound", "cart-summary", this.onCartCalloutOpened);
            add("saveForLaterButtonInserted", this._onSaveForLaterButtonInserted);
            add("cartSummaryShown", this.closeSaveForLaterCallout);
            add("fixPageContent", this.fixPageContent);
            add("unFixPageContent", this.unFixPageContent);
            add("showInstantFeedback", this.showInstantFeedback);

            this._boundOpenCartCallout = this.onCartCalloutOpened.bind(this);
            globalEvents.on("added-to-bag", this._boundOpenCartCallout);
        },

        init() {
            this.attachShippingOverlayWatcher();
            this.crmSurveyOverlay;
            this.overlayStack = new Stack();
            this.sflProductIdsQueue = [];
            this.lastScrollTop = window.pageYOffset;
            this._trackPWAAppInstallEvent();
            this._trackPWAHomescreenLaunch();
            this._trackPWAMode();
            this._redirectProxySites();
        },

        onInsert() {
            this._addSupportClasses();

            // these are messages created using the Django messages framework
            // See https://docs.djangoproject.com/en/1.8/ref/contrib/messages/ for more info
            const messages = environment.get("messages", []);
            messages.forEach((message) => {
                if (!message.tags.includes("toast")) {
                    alerts.alert(message.message);
                }
            });

            const parsedURL = url.parse(globals.window.location);

            // show instant notification if registered = "true" and area are set
            const notificationArea = parsedURL.searchParams.get("area");
            if (
                (parsedURL.searchParams.get("loggedin") === "true" ||
                    parsedURL.searchParams.get("registered") === "true") &&
                notificationArea
            ) {
                this.showInstantFeedback({ area: notificationArea });
            }

            // fire stock check on page load
            const header = this.findComponent(APP_HEADER_COMPONENT);
            if (header) {
                let stickyBuyButton = this.findComponent("buybuybuy-sticky-buy-button");

                if (stickyBuyButton) {
                    stickyBuyButton.setHeaderHeightReference(
                        header.el.getBoundingClientRect().height
                    );
                }
            }
        },

        onRemove() {
            this.releaseGlobalHandler("mouseleave", this.onBodyMouseLeave);
            globalEvents.off("added-to-bag", this._boundOpenCartCallout);
        },

        /**
         * Block the scrolling of the body.
         */
        blockScrolling() {
            this.el.classList.add("no-scroll");
        },

        /**
         * Un-block the scrolling of the body.
         */
        unblockScrolling() {
            this.el.classList.remove("no-scroll");
        },

        /**
         * A function to reference when we want to prevent a default browser behaviour
         * @param {Event} event
         */
        preventDefault(event) {
            event.preventDefault();
            return false;
        },
        fixPageContent() {
            this.el.classList.add("fix-element");
        },

        unFixPageContent() {
            this.el.classList.remove("fix-element");
        },

        /**
         * Tracks PWA appinstalled event.
         * @private
         */
        _trackPWAAppInstallEvent() {
            window.addEventListener("appinstalled", function () {
                analytics.event("pwa", "installed");
            });
        },

        /**
         * Tracks if user launched PWA from homescreen
         * @private
         */
        _trackPWAHomescreenLaunch() {
            var params = this._getParamsFromRequest();
            if (params.get("utm_medium") === "pwa") {
                analytics.event("pwa", "homescreen_launch");
            }
        },

        /**
         * Tracks if user is currently in PWA
         * @private
         */
        _trackPWAMode() {
            var isPWAinBrowser = true;

            if (matchMedia("(display-mode: standalone)").matches) {
                // Android and iOS 11.3+
                isPWAinBrowser = false;
            } else if ("standalone" in navigator) {
                // useful for iOS < 11.3
                isPWAinBrowser = !navigator.standalone;
            }

            if (!isPWAinBrowser) {
                analytics.event("pwa", "is_pwa");
            }
        },

        /**
         * If the page is being loaded on a non-lyst domain, redirect back to Lyst.
         * @private
         */
        _redirectProxySites() {
            // Attempt to guess if this is an allowed domain.
            const hostname = document.location.hostname;
            const href = document.location.href;
            const hostWhitelist = /(lyst|127\.0\.0\.1|localhost|nip\.io|archive\.org|google)/;
            const hrefWhitelist = /translate.*\/.*\?.*=.*lyst/;

            if (hostname.match(hostWhitelist) || href.match(hrefWhitelist)) {
                return;
            } else {
                document.location.href = `https://www.lyst.com${document.location.pathname}?nonlystdomainredirect=1`;
            }
        },

        _getParamsFromRequest() {
            var parsed = url.parse(globals.window.location.href);
            return parsed.searchParams;
        },

        /**
         * Adds classes to this component that indicate whether certain
         * features are supported or not.
         * @private
         */
        _addSupportClasses() {
            var classList = this.el.classList;

            // add class to the body to indicate whether this device has touch support
            classList.add(browser.hasTouch ? "has-touch" : "no-touch");

            // if device supports css transforms add class to body for CSS to hook into
            classList.toggle("has-css-transforms", browser.supportsCSSTransforms);

            // if device has css transitions
            classList.toggle("no-css-transitions", !browser.supportsCSSTransitions);

            // If scrollbars are stylable, add a class to help.
            classList.toggle("css-scrollbars", browser.supportsCSSScrollbars);
        },

        onCartCalloutOpened() {
            // show desktop header, add matte, prevent scrolling
            this.invoke(this.findComponent("desktop-header"), "show");
            this.el.classList.add(LYST_APP_CALLOUT_SHOWN_MODIFIER);
        },

        closeCartSummary() {
            const header = this.findComponent(APP_HEADER_COMPONENT);

            if (header) {
                const cartSummary = header.findComponent("cart-summary");
                this.invoke(cartSummary, "close");
            }
        },

        _onOverlayCloseIntent(event) {
            const activeOverlay = this.overlayStack.peek();

            // If the event is not coming from the active
            // overlay, preventDefault and do nothing.
            if (activeOverlay !== event.target) {
                event.preventDefault();
                return;
            }
        },

        _onOverlayClose() {
            const header = this.findComponent("app-header");

            this.overlayStack.pop();

            // If there is no active overlay,
            // unblock the scrolling
            if (this.overlayStack.isEmpty()) {
                if (header && header.openCallouts) {
                    return;
                }

                this.unblockScrolling();
                this.unFixPageContent();
            }
        },

        /**
         * On every save for later button insert event, pushes the product ID
         * into a list. Then fetches the pins and re-render the SFL button
         * if it has to.
         *
         * @param {Event} event
         */
        _onSaveForLaterButtonInserted(event) {
            this.sflProductIdsQueue.push(event.productId);
            this._fetchSaveForLaterPins();
        },

        /**
         * Requests the pins API endpoint passing the list of product ids in the
         * page. That will return an object with a map of the ids to their pin
         * status, i.e.
         *      {
         *          00000: True,  # The user has saved/pinned this product...
         *          00101: False, # ... But not this one.
         *          01010: True   # etc.
         *      }
         * Runs every 50ms – debounced.
         */
        _fetchSaveForLaterPins: _debounce(function () {
            const endpoint = "/services/pins/";
            const query = url.toURLSearchParams({
                product_id: [...this.sflProductIdsQueue],
            });

            this.sflProductIdsQueue = [];

            requester
                .get(endpoint, query)
                .then((data) => {
                    this._setPinnedProducts(data.pin_mapping);
                })
                .catch((error) => logging.error(error));
        }, 50),

        /**
         * Loops through the save for later buttons in the page and re-renders them
         * based on the user-saved product pins.
         *
         * @param {Object} pinnedProductsMapping - The product id mapping
         */
        _setPinnedProducts(pinnedProductsMapping) {
            const sflButtons = this.findComponents("save-for-later-button");
            sflButtons.forEach((saveForLaterButton) => {
                const isPinned = pinnedProductsMapping[saveForLaterButton.options.productId];
                if (isPinned) {
                    saveForLaterButton._render(true);
                }
            });
        },

        showInstantFeedback({ area }) {
            setTimeout(() => {
                /* skip a tick to allow the React component to initialize */
                globalEvents.trigger("instant-feedback-show", area);
            });
            this.findComponents("instant-feedback").forEach((component) => {
                if (component.options.area === area) {
                    component.show();
                }
            });
        },

        attachShippingOverlayWatcher() {
            watchState(
                (state) => {
                    return state.shippingAndReturnsOverlayReducer;
                },
                (overlayState) => {
                    if (!overlayState.open) {
                        return;
                    }
                    const linkId = overlayState.linkId;
                    const shippingAndReturnsOverlay = new ShippingAndReturnsOverlay({ linkId });

                    // append overlay to the document body, because this component will typically
                    // be shown in an accordion, which is position relative and overflow hidden
                    // so would not display correctly
                    shippingAndReturnsOverlay.appendTo(document.body);
                    shippingAndReturnsOverlay.open();
                    analytics.event("buy_area", "show_shipping_and_returns");
                }
            );
        },
    }
);
